:root {
  /* Color */
  --varPrimary500: var(--tkww-union-color-primary-500, #ac007c);
  --varPrimary400: var(--tkww-union-color-primary-400, #d700a0);
  --varPrimary300: var(--tkww-union-color-primary-300, #ff44cb);
  --varPrimary200: var(--tkww-union-color-primary-200, #febbf7);
  --varPrimary100: var(--tkww-union-color-primary-100, #ffe4fb);
  --varNeutralBlack: var(--tkww-union-color-neutral-black, #000);
  --varNeutral600: var(--tkww-union-color-neutral-600, #37393f);
  --varNeutral500: var(--tkww-union-color-neutral-500, #51545c);
  --varNeutral400: var(--tkww-union-color-neutral-400, #9699a0);
  --varNeutral300: var(--tkww-union-color-neutral-300, #caccd0);
  --varNeutral200: var(--tkww-union-color-neutral-200, #e4e5e9);
  --varNeutral100: var(--tkww-union-color-neutral-100, #f1f2f4);
  --varNeutralWhite: var(--tkww-union-color-neutral-white, #fff);
  --varAdditional01Extra500: var(--tkww-union-color-additional-01extra-500, #364c9d);
  --varAdditional01Extra400: #0073e6;
  --varAdditional01Extra300: var(--tkww-union-color-additional-01extra-300, #a1cdf5);
  --varAdditional01Extra200: var(--tkww-union-color-additional-01extra-200, #c4dff9);
  --varAdditional01Extra100: var(--tkww-union-color-additional-01extra-100, #e6f2fc);
  --varAdditional02Extra500: var(--tkww-union-color-additional-02extra-500, #fb8b00);
  --varAdditional02Extra400: var(--tkww-union-color-additional-02extra-400, #fbaf00);
  --varAdditional02Extra300: var(--tkww-union-color-additional-02extra-300, #fbbb03);
  --varAdditional02Extra200: var(--tkww-union-color-additional-02extra-200, #fdde81);
  --varAdditional02Extra100: var(--tkww-union-color-additional-02extra-100, #fff7e1);
  --varAdditional03Extra500: var(--tkww-union-color-additional-03extra-500, #db4936);
  --varAdditional03Extra400: #fa592d;
  --varAdditional03Extra300: var(--tkww-union-color-additional-03extra-300, #ff6e00);
  --varAdditional03Extra200: var(--tkww-union-color-additional-03extra-200, #ff9357);
  --varAdditional03Extra100: var(--tkww-union-color-additional-03extra-100, #ffd1bd);
  --varAdditional04Extra500: var(--tkww-union-color-additional-04extra-500, #ad9587);
  --varAdditional04Extra400: var(--tkww-union-color-additional-04extra-400, #c7afa1);
  --varAdditional04Extra300: var(--tkww-union-color-additional-04extra-300, #e8d5ca);
  --varAdditional04Extra200: var(--tkww-union-color-additional-04extra-200, #f7e6dc);
  --varAdditional04Extra100: var(--tkww-union-color-additional-04extra-100, #fff5ed);
  --varStatusInfo300: var(--tkww-union-color-status-info-300, #114394);
  --varStatusInfo200: #0073e6;
  --varStatusInfo100: var(--tkww-union-color-status-info-100, #f4f9ff);
  --varStatusSuccess300: var(--tkww-union-color-status-success-300, #005a25);
  --varStatusSuccess200: var(--tkww-union-color-status-success-200, #008a05);
  --varStatusSuccess100: var(--tkww-union-color-status-success-100, #e2ffee);
  --varStatusWarning300: var(--tkww-union-color-status-warning-300, #823300);
  --varStatusWarning200: var(--tkww-union-color-status-warning-200, #c25400);
  --varStatusWarning100: var(--tkww-union-color-status-warning-100, #fff3dd);
  --varStatusError300: var(--tkww-union-color-status-error-300, #a1000b);
  --varStatusError200: var(--tkww-union-color-status-error-200, #eb1400);
  --varStatusError100: var(--tkww-union-color-status-error-100, #ffece4);

  /* Spacing */
  --varSpDown5: 0.0625rem;
  --varSpDown4: 0.125rem;
  --varSpDown3: 0.25rem;
  --varSpDown2: 0.5rem;
  --varSpDown1: 0.75rem;
  --varSpBase: 1rem;
  --varSpUp1: 1.25rem;
  --varSpUp2: 1.5rem;
  --varSpUp3: 1.75rem;
  --varSpUp4: 2rem;
  --varSpUp5: 2.25rem;
  --varSpUp6: 2.5rem;
  --varSpUp7: 2.75rem;
  --varSpUp8: 3rem;
  --varSpUp9: 3.25rem;
  --varSpUp10: 3.5rem;
  --varSpUp11: 3.75rem;
  --varSpUp12: 4rem;

  /* Typography - Font Family */

  --varPrimaryFontFamily: var(
    --tkww-union-typography-primary-font-family,
    union-primary-font,
    union-sans-serif,
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif
  );
  --varSecondaryFontFamily: var(
    --tkww-union-typography-secondary-font-family,
    union-secondary-font,
    union-serif,
    Georgia,
    'Times New Roman',
    Times,
    serif
  );
  --varOverlineFontFamily: var(
    --tkww-union-typography-category-overline-font-family,
    var(
      --tkww-union-typography-primary-font-family,
      union-primary-font,
      union-sans-serif,
      -apple-system,
      system-ui,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      sans-serif
    )
  );

  /* Typography - Font Size */
  --varFontSize100: var(--tkww-union-typography-scale-100-font-size, 0.75rem);
  --varFontSize200: var(--tkww-union-typography-scale-200-font-size, 0.875rem);
  --varFontSize300: var(--tkww-union-typography-scale-300-font-size, 1rem);
  --varFontSize400: var(--tkww-union-typography-scale-400-font-size, 1.125rem);
  --varFontSize500: var(--tkww-union-typography-scale-500-font-size, 1.25rem);
  --varFontSize600: var(--tkww-union-typography-scale-600-font-size, 1.5rem);
  --varFontSize700: var(--tkww-union-typography-scale-700-font-size, 1.75rem);
  --varFontSize800: var(--tkww-union-typography-scale-800-font-size, 2rem);
  --varFontSize900: var(--tkww-union-typography-scale-900-font-size, 2.25rem);
  --varOverlineFontSize: var(
    --tkww-union-typography-category-overline-font-size,
    var(--tkww-union-typography-scale-100-font-size, 0.75rem)
  );

  /* Typography - Line Height */
  --varLineHeight100: var(--tkww-union-typography-scale-100-line-height, 1.34);
  --varLineHeight200: var(--tkww-union-typography-scale-200-line-height, 1.42857);
  --varLineHeight300: var(--tkww-union-typography-scale-300-line-height, 1.5);
  --varLineHeight400: var(--tkww-union-typography-scale-400-line-height, 1.3333);
  --varLineHeight450: var(--tkww-union-typography-scale-450-line-height, 1.78);
  --varLineHeight500: var(--tkww-union-typography-scale-500-line-height, 1.4);
  --varLineHeight600: var(--tkww-union-typography-scale-600-line-height, 1.333375);
  --varLineHeight700: var(--tkww-union-typography-scale-700-line-height, 1.286);
  --varLineHeight800: var(--tkww-union-typography-scale-800-line-height, 1.25);
  --varLineHeight900: var(--tkww-union-typography-scale-900-line-height, 1.2225);
  --varOverlineLineHeight: var(
    --tkww-union-typography-category-overline-line-height,
    var(--tkww-union-typography-scale-100-line-height, 1.34)
  );

  /* Typography - Font Weight */
  --varRegularFontWeight: var(--tkww-union-typography-regular-font-weight, 400);
  --varBoldFontWeight: var(--tkww-union-typography-bold-font-weight, 500);
  --varExtraBoldFontWeight: var(--tkww-union-typography-extra-bold-font-weight, 800);
  --varOverlineFontWeight: var(
    --tkww-union-typography-category-overline-font-weight,
    var(--tkww-union-typography-bold-font-weight, 700)
  );

  /* Typography - Letter Spacing */
  --varOverlineLetterSpacing: var(--tkww-union-typography-category-overline-letter-spacing, 1px);

  /* Typography - Text Transform */
  --varOverlineTextTransform: var(--tkww-union-typography-category-overline-text-transform, uppercase);

  /* Shadow */
  --varShadow: var(--tkww-union-shadow-box-shadow, 0 2px 4px 0 rgba(31, 31, 31, 0.12));

  /* Tokens */
  --root-max-width: 1360px;

  /* Select Tokens */
  --select-border-radius: 0.062rem;
  --select-item-highlight-color: var(--varAdditional04Extra100);
  --select-item-highlight-text-decoration: underline;

  /* Radio Group Tokens */
  --radio-group-border: 0.375rem;

  /* Button Tokens */
  --button-border-radius: 1.5625rem;
  --button-background-default-primary: var(--varPrimary400)
    radial-gradient(circle, transparent 1%, var(--varPrimary400) 1%) center/15000%;
  --button-background-default-secondary: var(--varPrimary100)
    radial-gradient(circle, transparent 1%, var(--varPrimary100) 1%) center/15000%;
  --button-hover--color-default-secondary: var(--varPrimary300);
  --button-active-background-color-default-primary: var(--varPrimary300);
  --button-active-background-color-default-secondary: var(--varPrimary200);

  /* Navigation Bullets Tokens */
  --navigation-bullets-size: 0.5rem;
  --navigation-bullets-border: none;
  --navigation-bullets-border-active: none;
  --navigation-bullets-background: var(--varNeutral200);
  --navigation-bullets-active-background: var(--varNeutralBlack);

  /* Cart Label Tokens */
  --cart-label-background-color: var(--varNeutralBlack);
  --cart-label-color: var(--varNeutralWhite);
}

@media (max-width: 767.98px) {
  :root {
    /* Typography - Font Size */
    --varFontSize500: 1.125rem;
    --varFontSize600: 1.25rem;
    --varFontSize700: 1.625rem;
    --varFontSize800: 1.75rem;
    --varFontSize900: 1.875rem;

    /* Typography - Line Height */
    --varLineHeight500: 1.33333;
    --varLineHeight600: 1.4;
    --varLineHeight700: 1.23077;
    --varLineHeight800: 1.21429;
    --varLineHeight900: 1;
  }
}

